import parse from 'html-react-parser';

import { wrapDefaultRichTextReplacer } from '@/components/RichText/richTextReplacer';

export { domToReact } from 'html-react-parser';

const noop = () => null;

export default function RichText({
  data,
  replace = noop,
  includeEditorStyles,
}) {
  const children = parse(data, {
    replace: wrapDefaultRichTextReplacer(replace),
  });

  if (includeEditorStyles) {
    return <div className="ck-content">{children}</div>;
  }

  return children;
}
