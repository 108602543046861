import { Table as MuiTable } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Table = styled(MuiTable)(({ theme }) => ({
  '& th': {
    ...theme.typography.body2,
    borderBottom: `1px solid ${theme.palette.grayscale[200]}`,
  },
  '& td': {
    ...theme.typography.body1,
    padding: theme.spacing(2),
    verticalAlign: 'top',
    borderBottom: `1px solid ${theme.palette.grayscale[200]}`,
  },
}));
